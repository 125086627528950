import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  title?: string | undefined;
  description?: string;
  keywords?: string;
  lang?: string;
  meta?: string[];
  path?: string;
}

const Seo: React.FC<Props> = (props: Props) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang,
      }}
      title={props.title}
    >
      {props.description && <meta name="description" content={props.description} />}
      {props.keywords && <meta name="keywords" content={props.keywords} />}
      <link rel="canonical" href={'https://raschetsten.ru' + props.path}/>
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: 'ru',
  meta: [],
  description: '',
  title: 'Расчетстен.рф',
  keywords: '',
  path: '',
};

export default Seo;
